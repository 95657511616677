import { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import {
  BagCheck,
  CartCheck,
  CheckCircle,
  Pen,
  People,
  PlusCircle,
  PrinterFill,
  Recycle,
  Trash
} from "react-bootstrap-icons";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import Swal from "sweetalert2";
import th from "date-fns/locale/th";
import { apiService } from '../functions/api-service';
import {ChangeEvent, OrderTransaction, User} from '../type';
import { Alert } from '../functions/utils';
import { useContexts } from "../context/AppContext";
import BtnPrintSummaryChemo from '../components/printSummaryChemo';
import BtnPrintQrGroupChemo from '../components/printGroupQrChemo';
import BtnPrintQrChemo from '../components/printQrChemo';
import FilterOrderTran from "../components/filterOrderTran";
import ModalOrderTransaction from "../components/modalOrderTran";
import ModalRemedOrderTransaction from "../components/modalRemedOrderTran";
import BtnPrintStar from "../components/printStar";
import ModalPrintQrByDate from "../components/modalPrintQrByDate";

export default function Home() {
  const { isLoad, isLoaded,profile } = useContexts();


  let checkHn = "";
  const statusMachine = [
    { value: "1", lable: "Ready" },
    { value: "2", lable: "Running" },
    { value: "3", lable: "Finished" },
    { value: "4", lable: "Error" },
  ];
  const statusOrder = [
    { value: "1", lable: "รับแล้ว" },
    { value: "2", lable: "เช็คยา" },
    { value: "3", lable: "จ่ายยา" },
    { value: "99", lable: 'Error' },
  ];
  const [orderTranList, setOrderTranList] = useState<Array<OrderTransaction>>([]);
  const [searchOrderTran, setSearchOrderTran] = useState({
    person: "",
    medName: "",
    orderDate: new Date(),
    dept: "",
    statusMachine: "",
    statusOrder: "",
  });
  const [openFormOrderTran, setOpenFormOrderTran] = useState<{
    show: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });

  const [openFormRemedOrderTran, setOpenFormRemedOrderTran] = useState<{
    show: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });

  const [openFromPrintQrByDate, setOpenFromPrintQrByDate] = useState<{
    show: boolean;
    data: OrderTransaction | null;
    saved: boolean;
  }>({ show: false, data: null, saved: false });


  const filteredItems = orderTranList && orderTranList.filter(
      (tran) => {


        let result = (
            (tran.med && tran.med?.name.includes(searchOrderTran.medName)) &&
            ((tran.dept && tran.dept?.includes(searchOrderTran.dept)) || tran.dept == null) &&

            (tran.person && tran.person?.fullName.includes(searchOrderTran.person)
            || tran.person && tran.person?.hn.includes(searchOrderTran.person)
            || tran.orderID?.includes(searchOrderTran.person)));

        if(searchOrderTran.statusMachine && result){
          result = tran.statusMachine === searchOrderTran.statusMachine
        }
        if(searchOrderTran.statusOrder && result){
          result = tran.statusOrder === searchOrderTran.statusOrder
        }

        return result;
      }
  );

  useEffect(() => {
    getOrderTranList().catch(console.error);
  }, [searchOrderTran.orderDate]);

  useEffect(() => {
    if (openFormOrderTran.saved) {
      getOrderTranList().catch(console.error);
      setOpenFormOrderTran((prev) => ({
        ...prev,
        saved: false,
      }));
    }
  }, [openFormOrderTran.saved]);

  useEffect(() => {
    if (openFormRemedOrderTran.saved) {
      getOrderTranList().catch(console.error);
      setOpenFormRemedOrderTran((prev) => ({
        ...prev,
        saved: false,
      }));
    }
  }, [openFormRemedOrderTran.saved]);

  const openModalPrintQrByDate = () => {
    setOpenFromPrintQrByDate({ show: true, data: null, saved: false })
  }

  const getOrderTranList = async () => {
    isLoad();
    setOrderTranList(
        await apiService.orderTranList({createAt: format(new Date(searchOrderTran.orderDate), "yyyy-MM-dd")})
    );
    isLoaded();
  };

  const handleChange = ( e: ChangeEvent) => {
    if (e.target.value && e.target.name === 'person') {
      // e.target.value = e.target.value.split(',')[0] || e.target.value;
      // e.target.value = e.target.value.split('*')[0] || e.target.value;
      e.target.value =  e.target.value.slice(0, 11) || e.target.value;
    }
    setSearchOrderTran((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  const removeOrderTran = async (data: OrderTransaction) => {
    await Swal.fire({
      title: "ต้องการลบรายการ ",
      html: `<h4>TaskID: ${data.orderID}</h4>`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ปิด",
    }).then(async (result) => {
      if (result.isConfirmed && data.orderID) {
        const res = await apiService.removeOrderTran(data.orderID);
        if (res.success === true) {
          getOrderTranList().then();
        }
        Alert(res.success, res.message);
      }
    });
  };

  const trHeader = (r: OrderTransaction, i: number) => {

    return(
      <tr className={"bg-primary bg-opacity-25"} key={i.toString() +'_'+ r?.person?.hn}>
        <td colSpan={13}>
          <div className={"row"}>
            <div className={"col-3"}>
              <strong>HN : </strong> {r?.person?.hn} {r?.person?.fullName}
            </div>
            <div className={"col-3"}>
              <strong>Treatment : </strong> {r.treatmentName}{" "}
              <strong>Cycle : </strong>{r.cycle}{" "}
              <strong>Day : </strong>{r.day}
            </div>
            <div className={"col-3"}>
              <strong>วันที่เริ่ม</strong>{" "}
              {r.orderDate && format(new Date(r.orderDate), "dd MMMM yyyy", {locale: th})}
            </div>
            <div className={"col-3 text-end"}>
              {!(profile?.site == '2' && profile?.sub == '0') &&
              <Button
                  size="sm"
                  variant="success"
                  name={"addOrder" + r?.person?.hn}
                  id={"addOrder" + r?.person?.hn}
                  onClick={() => {
                    setOpenFormOrderTran({
                      show: true,
                      data: {treatmentName:r.treatmentName,day:r.day,cycle:r.cycle,dept:r.dept, person: r.person},
                      saved: false,
                    })
                  }}
              >
                <PlusCircle /> เพิ่มรายการ
              </Button>
              }

              <BtnPrintSummaryChemo data={r} orderQrTranList={filteredItems}  contentType={'Summary'}/>
              <BtnPrintQrGroupChemo data={r} site={profile?.site} orderQrTranList={filteredItems} contentType={'QrGroup'}/>

            </div>
          </div>
        </td>
      </tr>
  )};

  const setOrderStatus =async (orderId: string | undefined, status: string) => {
    const res = await apiService.updateOrderStatus(orderId,status);
    if ( res.success === true ) {
      getOrderTranList().then();
    }
    Alert(res.success, res.message);
  }

  const setOrderPrint =async (orderId: string | undefined, print: number) => {
    const res = await apiService.updateOrderPrint(orderId,(print ==0? 1 :0));
    if ( res.success ) {
      getOrderTranList().then();
    }
    Alert(res.success, res.message);
  }

  const  btnOrderStatus =(r:OrderTransaction) =>{
    if(profile?.sub != '0'){
      return ('')
    }
    if(r.statusOrder == '1'){
      return (<Button title={"set to check"} variant="success" size="sm" onClick={() => setOrderStatus(r?.orderID,"2").then()}>
        <CheckCircle />
      </Button>)

    }else if(r.statusOrder == '2'){
      return (<Button title={"set to pay"} variant="primary" size="sm" onClick={() => setOrderStatus(r?.orderID,"3").then()}>
        <CartCheck />
      </Button>)
    }else{
      return ('')
    }
  };

  const trDetail = (r: OrderTransaction, i: number) => (<tr key={i}>
    <td>
      {r.chemoPull && parseFloat(r.chemoPull) > 0 && parseFloat( r.chemoPull as string).toFixed(2)}
      {r.chemoPullGem && parseFloat(r.chemoPullGem) > 0 &&  parseFloat(r.chemoPullGem as string).toFixed(2)}
      <br/>
      ({r.perTime && parseFloat(r.perTime) > 0 && parseFloat( r.perTime as string).toFixed(2)} mg)
    </td>
    <td>{r.med?.name}<div>
      <small>Day : {r.noDay}</small>
      <small> ขวดที่ {r.noInDay}</small></div></td>
    <td>
      {r.dosageOld && `${r.dosageOld} จำนวน ${r.amountOld}`}
    </td>
    <td>{r.dosage1 && `${r.dosage1} จำนวน ${r.amount1} ขวด`}</td>
    <td>{r.dosage2 && `${r.dosage2} จำนวน ${r.amount2} ขวด`}</td>
    <td>
      {r.med?.scsGem?.name && r.med.scsGem.name + (r.scsGemUnitOld??'')}
    </td>
    <td>
      {r?.scsId && r?.med && r?.med?.scsList.length > 0 &&
          r.med.scsList.find(e => e.id === r.scsId)?.scs?.name}
    </td>
    <td>{(r.method?.name??'') + " " + (r.time?.name??'')}</td>
    <td className={"text-center"}>
      {r.orderDate && format(new Date(r.orderDate), "dd MMM yyyy", {locale: th})}
    </td>
    <td>{r.dept}</td>
    <td>{statusMachine.find(e => e.value === r.statusMachine)?.lable}</td>
    <td>{statusOrder.find(e => e.value === r.statusOrder)?.lable}
        {btnOrderStatus(r)}
      {" "}
      <>{profile?.site =='2' ? <Button
          variant={r.printed == 1 ? 'success':'outline-primary'}
          size="sm"
          onClick={() => setOrderPrint(r.orderID,r.printed??0).then()}
      >
        {r.printed == 1 ? 'พิมพ์แล้ว':'ยังไม่พิมพ์'}
      </Button> :''}
      </>
    </td>
    <td className={"text-center"}>
      <Button
          size="sm"
          onClick={() =>
              setOpenFormOrderTran({
                show: true,
                data: r,
                saved: false,
              })
          }
      >
        <Pen />
      </Button>{" "}
      <Button
          variant="danger"
          size="sm"
          onClick={() => removeOrderTran(r).then()}
      >
        <Trash />
      </Button>

      <BtnPrintQrChemo data={r} site={profile?.site} contentType={'QrCode'}/>


    </td>
  </tr>)
  const updateOrderDetailAll =async () => {}

  return (
      <>
      <FilterOrderTran
            handleChange={handleChange}
            openFormOrderTran={() => setOpenFormOrderTran({ show: true, data: null, saved: false })}
            openFormRemedOrderTran={() => setOpenFormRemedOrderTran({ show: true, data: null, saved: false })}
            orderDate={searchOrderTran.orderDate}
            dept={searchOrderTran.dept}
            type='home'
            updateOrderDetailAll={() => updateOrderDetailAll()}
        />
        <Container className={"mw-100"}>
          <Table striped bordered hover responsive={"xl"}>
            <thead>
            <tr>
              <th>ดูด</th>
              <th>รายการยา</th>
              <th>ขวดที่เหลือ</th>
              <th>ขวดที่ 1</th>
              <th>ขวดที่ 2</th>
              <th>น้ำเกลือทำละลาย</th>
              <th>น้ำเกลือผสม</th>
              <th>วิธีใช้ยา</th>
              <th>วันที่สั่ง</th>
              <th>แผนก</th>
              <th>สถานะ Machine</th>
              <th>สถานะ Order</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colSpan={13} className={"bg-info bg-opacity-50 p-1"}
                  // style={{textAlign:"right"}}
              >
                <Button
                    size="sm"
                    variant="primary"
                    name="reMed"
                    id="reMed"
                    onClick={() => openModalPrintQrByDate()}
                >
                  <PrinterFill /> พิมพ์ QR ด้วยวันที่
                </Button>

                {/*<BtnPrintStar/>*/}
            </td>
            </tr>
            {filteredItems && filteredItems.map((r, i) => {
              if (checkHn !== r?.person?.hn) {
                checkHn = r?.person?.hn ? r.person.hn : '';
                return <>{trHeader(r, i)} {trDetail(r, i)}</>;
              }else{
                return trDetail(r, i);
              }
            })}
            </tbody>
          </Table>
        </Container>
        <ModalOrderTransaction
            show={openFormOrderTran.show}
            data={openFormOrderTran.data}
            showError={false}
            handleClose={() =>
                setOpenFormOrderTran((prev) => ({
                  ...prev,
                  show: false,
                }))
            }
            saved={() =>
                setOpenFormOrderTran((prev) => ({
                  ...prev,
                  saved: true,
                }))
            }
        />

        <ModalRemedOrderTransaction
            show={openFormRemedOrderTran.show}
            data={openFormRemedOrderTran.data}
            handleClose={() =>
                setOpenFormRemedOrderTran((prev) => ({
                  ...prev,
                  show: false,
                }))
            }
            saved={() =>
                setOpenFormRemedOrderTran((prev) => ({
                  ...prev,
                  saved: true,
                }))
            }
        />

        <ModalPrintQrByDate
            show={openFromPrintQrByDate.show}
            data={openFromPrintQrByDate.data}
            handleClose={() =>
                setOpenFromPrintQrByDate((prev) => ({
                  ...prev,
                  show: false,
                }))
            }
            saved={() =>
                setOpenFromPrintQrByDate((prev) => ({
                  ...prev,
                  saved: true,
                }))
            }
        />

      </>
  );
}