import React, {ReactNode, useEffect, useState} from "react";
import {Button, Container, Nav, Table} from "react-bootstrap";
import {CartCheck, CheckCircle, Pen, PlusCircle, PrinterFill, Recycle, StarFill, Trash} from "react-bootstrap-icons";
import { apiService } from '../../functions/api-service';
import Swal from 'sweetalert2';
import {Alert, OptionSelects} from '../../functions/utils';
import { useContexts } from "../../context/AppContext";
import {ChangeEvent, Department, OptionSelect, OrderDetail, OrderTransaction} from "../../type";
import ModalDept from "../../components/modalDept";
import {format} from "date-fns";
import th from "date-fns/locale/th";
import FilterOrderTran from "../../components/filterOrderTran";
import DatePicker from "react-datepicker";
import {CSVDownload, CSVLink,} from "react-csv";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import Select from "react-select";

export default function SynReport1() {
  const { isLoad, isLoaded, profile } = useContexts();
  const navigate = useNavigate();
  useEffect(() => {
    if((profile?.sub !== '0')){
      navigate("/")
    }
  }, []);

  const statusMachine = [
    { value: "1", lable: "Ready" },
    { value: "2", lable: "Running" },
    { value: "3", lable: "Finished" },
    { value: "4", lable: "Error" },
  ];
  const statusOrder = [
    { value: "1", lable: "รับแล้ว" },
    { value: "2", lable: "เช็คยา" },
    { value: "3", lable: "จ่ายยา" },
    { value: "99", lable: 'Error' },
  ];

  const [deptList, setDeptList] = useState<Array<OptionSelect>>([]);

  const getDept  = async () => {
    setDeptList(
        OptionSelects(
            await apiService.deptList({ status: "1" }),
            "${name}",
            "id"
        )
    );
  };

  useEffect(() => {
    getDept().then();
  }, []);

  const [sumTreatment, setTreatment] = useState<Array<any>>([]);
  const [sumMedication, setMedication] = useState<Array<any>>([]);
  const [sumSCS, setSCS] = useState<Array<any>>([]);

  const [searchOrderTran, setSearchOrderTran] = useState({
    orderStartDate: new Date(),
    orderEndDate: new Date(),
    dept: '',

  });

  useEffect(() => {
    getTreatment().catch(console.error);
  }, [searchOrderTran.orderStartDate,searchOrderTran.orderEndDate,searchOrderTran.dept]);

  useEffect(() => {
    getMedication().catch(console.error);
  }, [searchOrderTran.orderStartDate,searchOrderTran.orderEndDate,searchOrderTran.dept]);

  useEffect(() => {
    getSCS().catch(console.error);
  }, [searchOrderTran.orderStartDate,searchOrderTran.orderEndDate,searchOrderTran.dept]);

  const getTreatment = async () => {
    isLoad();
    setTreatment(
        await apiService.listSumTreatment(
            { sDate: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),
              eDate : format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd"),
              dept:searchOrderTran.dept,
        })
    );
    isLoaded();
  };

  const getMedication = async () => {
    isLoad();
    setMedication(
        await apiService.listSumMedication(
            { sDate: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),
              eDate : format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd"),
              dept:searchOrderTran.dept,

            })
    );
    isLoaded();
  };



  const getSCS = async () => {
    isLoad();
    setSCS(
        await apiService.listSumSCS(
            { sDate: format(new Date(searchOrderTran.orderStartDate), "yyyy-MM-dd"),
              eDate : format(new Date(searchOrderTran.orderEndDate), "yyyy-MM-dd"),
              dept:searchOrderTran.dept,

            })
    );
    isLoaded();
  };

  const handleChange = ( e: ChangeEvent) => {
    setSearchOrderTran((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  const csvTreatment = [
    ["#","สูตรยา (Regimen)","จำนวน"]
  ];
  const csvMedication = [
    ["#","รายการยา", "จำนวน(ขวด)",]
  ];
  const csvSCS = [
    ["#","รายการน้ำเกลือ", "จำนวน(ขวด)",]
  ];
  // compare data for display and export
  for (let i = 0; i < sumTreatment.length; i++) {
    let r = sumTreatment[i];
    let teme:any =
        [ (i+1).toString(),(r?.name),
            r.qty];

    csvTreatment.push(teme)
  }
  for (let i = 0; i < sumMedication.length; i++) {
    let r = sumMedication[i];
    let teme:any =
        [ (i+1).toString(),
          (r?.name+" ( "+r.strength+" / "+r.solvent+" )"),
          r.qty];

    csvMedication.push(teme)
  }

  for (let i = 0; i < sumSCS.length; i++) {
    let r = sumSCS[i];
    let teme:any =
        [ (i+1).toString(),
          (r?.name),
          r.qty];

    csvSCS.push(teme)
  }

  return (
      <>
        <Container className={"mw-100"}>
          <h2>รายงานตามชนิดของยา และสูตรยา</h2>
          {/*<div  className="mb-2">ค้นหาข้อมูลได้จาก รหัส และชื่อ</div>*/}
          <div className="mb-2 row bg-primary bg-opacity-25 p-1">
            <div className={"col-3"}>
              <label htmlFor="orderStartDate" className={"form-label"}>
                วันที่เริ่ม
              </label>
              <DatePicker
                  id={"orderStartDate"}
                  name={"orderStartDate"}
                  selected={searchOrderTran.orderStartDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderStartDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>
            <div className={"col-3"}>
              <label htmlFor="orderEndDate" className={"form-label"}>
                วันที่สิ้นสุด
              </label>
              <DatePicker
                  id={"orderEndDate"}
                  name={"orderEndDate"}
                  selected={searchOrderTran.orderEndDate}
                  onChange={(date: Date) =>
                      handleChange({target: {name: "orderEndDate", value: date}})
                  }
                  locale={th}
                  dateFormat={"dd-MM-yyyy"}
                  className={"form-control"}
              />

            </div>
            <div className={"col-2"}>
              <label htmlFor="medName" className={"form-label"}>
                แผนก
              </label>
              <Select
                  value={searchOrderTran.dept
                      ? deptList.find((e) => e.data.name == searchOrderTran.dept)
                      : null}
                  isSearchable={true}
                  isClearable={true}
                  name="dept"
                  id="dept"
                  options={deptList}
                  onChange={(e) => handleChange({target: {name: 'dept', value: e?.data.name}})}
              />
            </div>
            <div className={"col-4 text-end"}>
              <CSVLink
                  data={csvTreatment}
                  filename={"report-treatment_" + moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                  className="btn btn-primary mt-3"
                  target="_blank"
              >
                Treatment
              </CSVLink>&nbsp;&nbsp;&nbsp;

              <CSVLink
                  data={csvMedication}
                  filename={"report-medication_" + moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                  className="btn btn-primary  mt-3"
                  target="_blank"
              >
                 Medication
              </CSVLink>&nbsp;&nbsp;&nbsp;

              <CSVLink
                  data={csvSCS}
                  filename={"report-SCS_" + moment(new Date()).format("DD-MM-YYYY") + ".csv"}
                  className="btn btn-primary  mt-3"
                  target="_blank"
              >
                 SCS
              </CSVLink>

            </div>
          </div>
          <div className={'row'}>
            <div className={"col-4"}>
              <Table striped bordered hover responsive={"xl"}>
                <thead className={'center'} style={{textAlign: "center",}}>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th colSpan={2}>สูตรยา (Regimen)</th>
                </tr>
                <tr>
                  <th>รายงานตามสูตรยา (Regimen)</th>
                  <th>จำนวน</th>
                </tr>
                </thead>
                <tbody>
                {sumTreatment && sumTreatment.map((r, i) => (
                    <tr>
                      <td> {i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{r.qty}</td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </div>

            <div className={"col-4"}>
              <Table striped bordered hover responsive={"xl"}>
                <thead className={'center'} style={{textAlign: "center",}}>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th colSpan={3}>ชนิดของยา</th>
                </tr>
                <tr>
                  <th>รายงานตามชนิดของยา</th>
                  <th>จำนวน</th>
                </tr>
                </thead>
                <tbody>
                {sumMedication && sumMedication.map((r, i) => (
                    <tr>
                      <td> {i + 1}</td>
                      <td>{r?.name} {"( "}{r.strength}{"/ "}{r.solvent}{" )"}</td>
                      <td>{r.qty}</td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </div>

            <div className={"col-4"}>
              <Table striped bordered hover responsive={"xl"}>
                <thead className={'center'} style={{textAlign: "center",}}>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th colSpan={3}>น้ำเกลือสำหรับผสมยา</th>
                </tr>
                <tr>
                  <th>รายงานตามชนิดน้ำเกลือสำหรับผสมยา</th>
                  <th>จำนวน</th>
                </tr>
                </thead>
                <tbody>
                {sumSCS && sumSCS.map((r, i) => (
                    <tr>
                      <td> {i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{r.qty}</td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </div>
          </div>


        </Container>

      </>
  );
}
