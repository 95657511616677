import DatePicker from "react-datepicker";
import th from "date-fns/locale/th";
import {Button, Container, Form} from "react-bootstrap";
import {PlusCircle, Recycle} from "react-bootstrap-icons";
import {ChangeEvent, OptionSelect} from '../type';
import React, {useEffect, useState} from "react";
import {OptionSelects} from "../functions/utils";
import {apiService} from "../functions/api-service";
import Select from "react-select";
import {useContexts} from "../context/AppContext";

type Props = {
    handleChange: (e: ChangeEvent) => void;
    openFormOrderTran: () => void;
    openFormRemedOrderTran: () => void;
    updateOrderDetailAll: () => void;
    orderDate: Date,
    dept: string,
    type:string,
  };
  
  export default function FilterOrderTran ( { handleChange, openFormOrderTran,openFormRemedOrderTran, orderDate,dept,type,updateOrderDetailAll }: Props ) {
      const {profile } = useContexts();
      const statusMachine = [
        { value: "1", lable: "Ready" },
        { value: "2", lable: "Running" },
        { value: "3", lable: "Finished" },
        { value: "4", lable: "Error" },
      ];
    const statusOrder = [
        { value: "1", lable: "รับแล้ว" },
        { value: "2", lable: "เช็คยา" },
        { value: "3", lable: "จ่ายยา" },
        { value: "99", lable: "Error" },
      ];

    const [deptList, setDeptList] = useState<Array<OptionSelect>>([]);

      const getDept  = async () => {
          setDeptList(
              OptionSelects(
                  await apiService.deptList({ status: "1" }),
                  "${name}",
                  "id"
              )
          );
      };

      useEffect(() => {
          getDept().then();
      }, []);

    return (
        <Container className={"mw-100  p-10"} style={{backgroundColor:"#0db4fd40",padding:"10px"}}>
            <div className="mb-2 row">
                <div className={"col-3"}>
                    <label htmlFor="person" className={"form-label"}>
                        HN, ชื่อ-นามสกุล,ID
                    </label>
                    <input
                        id={"person"}
                        name={"person"}
                        className={"form-control"}
                        type="text"
                        placeholder="ค้นหาข้อมูลผู้ป่วย..."
                        onChange={handleChange}
                    />
                </div>
                <div className={"col-3"}>
                    <label htmlFor="medName" className={"form-label"}>
                        ชื่อยา
                    </label>
                    <input
                        id={"medName"}
                        name={"medName"}
                        className={"form-control"}
                        type="text"
                        placeholder="ค้นหาข้อมูลยา..."
                        onChange={handleChange}
                    />
                </div>

                <div className={"col-2"}>
                    <label htmlFor="medName" className={"form-label"}>
                        แผนก
                    </label>
                    <Select
                        value={dept
                            ? deptList.find((e) => e.data.name == dept)
                            : null}
                        isSearchable={true}
                        // isClearable={true}
                        name="dept"
                        id="dept"
                        options={deptList}
                        onChange={(e) => handleChange({target: {name: 'dept', value: e?.data.name}})}
                    />
                </div>


                <div className={"col-1"}>
                    <label htmlFor="orderDate" className={"form-label"}>
                            {type == 'home' ? 'วันที่สร้าง' : 'วันที่สั่ง'}
                        </label>
                        <DatePicker
                            id={"orderDate"}
                            name={"orderDate"}
                            selected={orderDate}
                            onChange={(date: Date) =>
                                handleChange({target: {name: "orderDate", value: date}})
                            }
                            locale={th}
                            dateFormat={"dd-MM-yyyy"}
                            className={"form-control"}
                        />
                    </div>
                    <div className={"col-1"}>
                        <label htmlFor="statusMachine" className={"form-label "}>
                            สถานะหุ่นยนต์
                        </label>
                        <select
                            id="statusMachine"
                            name="statusMachine"
                            className="form-select"
                            onChange={(e) =>
                                handleChange({
                                    target: {name: "statusMachine", value: e.target.value},
                                })
                            }
                        >
                            <option key={99}></option>
                            {statusMachine.map((r, i) => (
                                <option value={r.value} key={i}>{r.lable}</option>
                            ))}
                        </select>
                    </div>
                    <div className={"col-1"}>
                        <label htmlFor="statusOrder" className={"form-label"}>
                            สถานะ Order
                        </label>
                        <select
                            id="statusOrder"
                            name="statusOrder"
                            className="form-select"
                            onChange={(e) =>
                                handleChange({
                                    target: {name: "statusOrder", value: e.target.value},
                                })
                            }
                        >
                            <option key={99}></option>
                            {statusOrder.map((r, i) => (
                                <option value={r.value} key={i}>{r.lable}</option>
                            ))}
                        </select>
                    </div>
                    <div className={"col-1 d-grid"}>
                        <label htmlFor="addOrder" className={"form-label"}>
                            &nbsp;
                        </label>
                        {type == 'home' && (<><Button
                            variant="primary"
                            name="reMed"
                            id="reMed"
                            onClick={() => openFormRemedOrderTran()}
                        >
                            <Recycle/> Re-med
                        </Button>
                        { !(profile?.userID != 28 && profile?.site == '2' && profile?.sub == '0') &&  <Button
                                variant="success"
                                name="addOrder"
                                id="addOrder"
                                onClick={() => openFormOrderTran()}
                            >
                                <PlusCircle/> เพิ่มรายการ
                            </Button>
                        }
                        </>
                            )}

                        {type == 'report' && (<> <Button
                            variant="primary"
                            name="reMed"
                            id="reMed"
                            onClick={() => updateOrderDetailAll()}
                        >
                            <Recycle/> Update All
                        </Button>
                        </>)}

                    </div>
                </div>
        </Container>
  )
  }